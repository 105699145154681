<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <section>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          ref="form" 
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card
                no-body
              >
                <b-card-body>
                  <div class="row">
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.title')"
                          label-for="title"
                        >
                          <b-form-input
                            id="title"
                            v-model="inputData.title"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in items"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >
                    <!-- Item Name -->
                    <template v-if="item.type == inputType.TEXT">
                      <b-col>
                        <b-form-group
                          class="w-100"
                          :label="$t('common.general.key_name')"
                          label-for="item-name"
                        >
                          <b-form-input
                            id="item-name"
                            v-model="item.field"
                            class="w-100"
                            type="text"
                            name="field"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          class="w-100"
                          :label="$t('common.general.value')"
                          label-for="value"
                        >
                          <b-form-input
                            id="value"
                            v-model="item.value"
                            class="w-100"
                            type="text"
                            name="value"
                          />
                        </b-form-group>
                      </b-col>
                      
                    </template>
                    <template v-else>
                      <b-col class="col-cus col-3">
                        <b-form-group
                          class="w-100"
                          :label="$t('common.general.key_name')"
                          label-for="item-name"
                        >
                          <b-form-input
                            id="item-name"
                            v-model="item.field"
                            class="w-100"
                            type="text"
                            name="field"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col class="col-cus col-5">                      
                        <b-media
                          no-body
                          class="mb-75 w-100"
                        >
                          <b-media-aside>
                            <b-overlay
                              :show="isUpload"
                              class="d-inline-block"
                            >
                              <b-img
                                rounded
                                :src="
                                  item.image ? item.image : imageDefault
                                "
                                height="80"
                              />
                            </b-overlay>
                          </b-media-aside>

                          <b-media-body class="mt-75 ml-75">
                            <b-form-group class="mb-0">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="openFile($event, item.id)"
                              >
                                {{ $t('common.general.upload') }}
                              </b-button>
                              <b-form-file
                                :ref="`refInputEl_${item.id}`"
                            
                                accept=".jpg, .png"
                                :hidden="true"
                                plain
                                @input="upload($event, item.id)"
                              />
                            </b-form-group>
                            <b-card-text>Allowed JPG or PNG.</b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                    </template>
                    <b-col class="col-cus col-2">
                      <b-form-checkbox
                        v-model="item.type"
                        :value="inputType.IMAGE"
                        :unchecked-value="inputType.TEXT"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        inline
                      >IMAGE
                      </b-form-checkbox>
                    </b-col>
                    <b-col class="col-cus col-2">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Delete</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add New</span>
                  </b-button>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions mt-md-0 mt-2"
            >
              <b-card>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t("common.form.save") }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BOverlay,
  BFormCheckbox,
  BFormFile,
  BMedia,
  BMediaBody,
  BMediaAside,
  BImg,
  BCardText,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import configureServices from '@/services/configureServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { imageDefault } from '@/services/constant';
import { heightTransition } from '@core/mixins/ui/transition';
import enums from '@/services/enumsServices';
import _ from 'lodash';
const InputType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
}
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BOverlay,
    BFormCheckbox,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BImg,
    BCardText,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      imageDefault,
      isLoading: false,
      id: this.$route.query.id,
      inputData: {},
      required,
      items: [],
      isUpload: false,
      inputType: InputType,
    };
  }, 
  created() {
    if (this.id) {
      this.getDetails();
    } 
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  }, 
  methods: {
    openFile($event, id){
      // eslint-disable-next-line prefer-template
      this.$refs['refInputEl_' + id][0].$el.click($event, id);
    },
    async onSubmit() {
      this.isLoading = true;
      this.inputData.structures = JSON.stringify(this.items);
      await configureServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({ name: 'list-configures' });
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getDetails() {
      this.isLoading = true;
      await configureServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getConfigure;
            this.items = JSON.parse(this.inputData.structures); 
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    repeateAgain() {
      this.items.push({
        id: commonServices.uuid(),
        type: this.inputType.TEXT,
        image: '',
        field: '',
        value: '',
      }); 
    },
    removeItem(index) {
      this.items.splice(index, 1); 
    }, 
    async upload($event, itemId) {
      this.isUpload = true;
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = async () => {
        await commonServices
          .uploadImage($event, 'client', enums.levelS3.public)
          .then((result) => {
            this.isUpload = false;
            const index = _.findIndex(this.items, x => x.id === itemId);            
            this.items[index].image = result;
          })
          .finally(() => {
            this.uploading = false;
          });
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
